import React from 'react';
import Loadable from 'react-loadable';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as HeroMyCourier }
  from '@shipae/components-sandbox/component/static-icons/hero-mycourier.svg'; // eslint-disable-line
import { ReactComponent as HeroInternationalShopping }
  from '@shipae/components-sandbox/component/static-icons/hero-internationalshopping.svg'; // eslint-disable-line

import { useTranslation } from 'src/views/misc/localization';
import {
  setHeroActiveTabId,
} from 'src/application/lazy/hero/actions';
import {
  getHero,
} from 'src/application/lazy/hero/selectors';
import {
  HeroMenuItemType,
} from 'src/infrastructure/gatsby/types/hero';
import MyCourier from '../../panels/my-courier';
import {
  Loading, PanelContainer, Tabs, Tab,
} from '../styles';

type PanelsMap = {
  [panelId: string]: any, // eslint-disable-line
};
const PANELS: PanelsMap = [
  {
    id: '5',
    tabTitle: 'heroMyCourierTabTitle',
    formTitle: 'heroMyCourierFormTitle',
    formComponent: 'myCourier',
    iconComponent: HeroMyCourier,
    jsx: MyCourier,
  },
  {
    id: '6',
    tabTitle: 'heroInternationalShoppingTabTitle',
    formTitle: 'heroInternationalShoppingFormTitle',
    formComponent: 'internationalShopping',
    iconComponent: HeroInternationalShopping,
    jsx: Loadable({
      loader: () => import('../../panels/international-shopping' /* webpackChunkName: "international-shopping" */) as any, // eslint-disable-line
      loading: () => <Loading>Loading...</Loading>,
    }),
  },
];

const Personal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    mobileHeroActiveTabId,
  } = useSelector(getHero);
  const activeTabId = mobileHeroActiveTabId || '5';
  const panel = PANELS.find((p: HeroMenuItemType) => p.id === activeTabId);
  const PanelJsx = panel?.jsx;

  return (
    <>
      <Tabs>
        {PANELS.map(
          (tab: HeroMenuItemType) => {
            const Icon = tab?.iconComponent;

            return (
              <Tab
                active={activeTabId === tab.id}
                onClick={() => dispatch(setHeroActiveTabId(tab.id, true))}
                data-ga-trigger
              >
                {Icon && (
                  <Icon
                    style={{ width: '4rem' }}
                  />
                )}
                {tab.tabTitle && t(tab.tabTitle)}
              </Tab>
            );
          },
        )}
      </Tabs>
      {panel && PanelJsx && (
        <PanelJsx
          id={panel.id}
          title={panel?.formTitle && t(panel.formTitle)}
          PanelContainer={PanelContainer}
        />
      )}
    </>
  );
};

export default Personal;
