import styled from 'styled-components';

export const Text = styled.p`
  padding: 5.6rem 0 0.8rem 0;
`;

export const IconsContainer = styled.div`
  display: flex;

  & svg {
    height: 3.8rem;
    margin-right: 1.6rem;
  }
`;
