/* eslint jsx-a11y/control-has-associated-label: 0 */
import React from 'react';
import TabPanel, {
  TabPanelPropsType,
} from '@shipae/components-sandbox/component/tabs/panel';
import {
  ReactComponent as AppStore,
} from '@shipae/components-sandbox/component/static-illustrations/app-store.svg'; // eslint-disable-line

import SmartLink from 'components/smart-link';
import { useTranslation } from 'src/views/misc/localization';
import { Title, PanelTheme } from '../styles';
import { IconsContainer, Text } from './styles';

const GOOGLE_PLAY = 'https://play.google.com/store/apps/details?id=carry.client.carry.ae'; // eslint-disable-line
const APP_STORE = 'https://apps.apple.com/ae/app/shipa-delivery/id1090897073'; // eslint-disable-line

type MyCourierProps = {
  id: string,
  title: string,
  PanelContainer?: React.FC<TabPanelPropsType>,
};
const MyCourier: React.FC<MyCourierProps> = ({
  id,
  title,
  PanelContainer = TabPanel,
}) => {
  const { t } = useTranslation();

  return (
    <PanelContainer id={id} theme={PanelTheme}>
      <Title>{title}</Title>
      <Text>{t('downloadApp')}</Text>
      <IconsContainer>
        <SmartLink href={APP_STORE}><AppStore /></SmartLink>
      </IconsContainer>
    </PanelContainer>
  );
};

export default MyCourier;
